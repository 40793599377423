<template>
  <div>
    <div v-for="challenge in challenges" :key="challenge.id">
      <div class="challenge" @click="viewChallenge(challenge.id)">
        <b-card no-body>
          <b-card-header>{{ challenge.away.name }} at {{ challenge.home.name }}</b-card-header>
          <b-card-body>
            <Faceoff :challenge="challenge" />
          </b-card-body>
        </b-card>
        <hr />
      </div>
      <hr />
    </div>
    <p style="text-align: center;">Calculate Week</p>
    <hr>
    <b-form-select v-model="week" :options="weeks" class="selector-week flex-grow-1 mr-3"></b-form-select>
    <b-button variant="danger" style="margin-top:10px" class="btn-block" @click="calcweek">Calculate</b-button>
    <p style="margin-bottom: 50px;">{{ calcmessage }}</p>
  </div>
</template>

<script>
import { api } from "@/utils/api";
import Faceoff from "@/components/Faceoff";

export default {
  name: "SubmitResults",
  data() {
    return {
      challenges: null,
      weeks: [],
      week: null,
      calcmessage: null
    };
  },
  components: {
    Faceoff,
  },
  created() {
    let self = this;
    api(
      `query{ needresults{ id spread totalpoints line { name } home { id name logo mascot } away { id name logo mascot } eventDate eventTime bets { id } } playableWeek { priority name } }`
    ).then((data) => {
      self.challenges = data.needresults;
      console.log(data.playableWeek)
      data.playableWeek.forEach((element) => {
        self.weeks.push({
          value: element.priority,
          text: element.name,
        });
      });
      self.week = data.playableWeek[0].priority
    });
  },
  methods: {
    viewChallenge(cid) {
      this.$router.push({
        name: "SubmitResult",
        params: { id: cid },
      });
    },
    calcweek() {
      let self = this;
      api(
        `mutation{ calculateWeek(week:${self.week}){ code message } }`
      ).then((data) => {
        if (data.calculateWeek.code === 0) {
          self.calcmessage = "Successful"
        } else {
          self.calcmessage = "Failed"

        }
      });
      console.log('calc week', this.week)
    }
  },
};
</script>

<style scoped>
.logo {
  margin: 20px;
}

.challenge {
  cursor: pointer;
  text-align: center;
}
</style>
